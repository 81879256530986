if ($('.block-econtrat-form').length > 0) {
    var actualFormHeight, actualFieldsetHeight;

    if ($(".toggleFormregisterContrat .field-error-content").length) {
        actualFieldsetHeight = $('.block-econtrat-form-fieldset').innerHeight();
        $('.block-econtrat-form').innerHeight(actualFieldsetHeight);
    }

    $('#contrat_register_optin').on('ifChecked', function (event) {
        actualFormHeight = $('.block-econtrat-form').innerHeight();
        actualFieldsetHeight = $('.block-econtrat-form-fieldset').innerHeight();
        if ($(window).width() <= 620) {
            actualFieldsetHeight += 50;
        }
        $('.block-econtrat-form').innerHeight(actualFieldsetHeight);
    });

    $('#contrat_register_optin').on('ifUnchecked', function (event) {
        var paddingTop = parseInt($('.block-econtrat-form-optin-row').css('paddingTop'));
        var paddingBottom = parseInt($('.block-econtrat-form-optin-row').css('paddingBottom'));
        var initialFormHeight = $('.block-econtrat-form-checkboxlabel').innerHeight() + paddingTop + paddingBottom;

        $('.block-econtrat-form').innerHeight(initialFormHeight);
    });

}


if ($('.contrats-promotional-blocks').length && $(window).width() < 1080) {
    $('.contrats-promotional-blocks').each(function () {

        var nbSlides = $(this).children('.bxslider').length;

        if (nbSlides > 2) {
            var slider = $(this).bxSlider({
                minSlides: 1,
                maxSlide: 4,
                moveSlide: 1,
                prevText: '',
                nextText: '',
                responsive: true,
                wrapperClass: 'bx-wrapper bx-wrapper-contrat-slider'
            });
        }
    })
}

$('.bounceAnimation').css('animation', 'bounce 2s ease 0s infinite normal none');

