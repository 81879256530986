/* Menu pharmacien */
if ($('.main_menu').length > 0) {
    $('.mm-root-link').on('click', function (e) {

        if ($(this).next('.mm-ssmenu').length > 0) {
            e.preventDefault();
            var $this = $(this);
            var $linkInside = $this.children('.mm-root-link-inside');
            var $ssMenu = $this.next('.mm-ssmenu');

            $('.mm-root-link').not($this).each(function () {
                if ($(this).children('.mm-root-link-inside').hasClass('fadeOut')) {
                    $(this).children('.mm-root-link-inside').removeClass('fadeOut');
                    $(this).next('.mm-ssmenu').removeClass('mm-ssmenu-open');
                }
            });
            e.stopPropagation();
            $linkInside.toggleClass('fadeOut');
            $ssMenu.toggleClass('mm-ssmenu-open');

        }
    });

    $('.mm-ssmenu').on('click', function (e) {
        e.stopPropagation();
    });

    $('.container-fluid, .mm-ssmenu-close').on('click', function () {
        $('.mm-root-link-inside').removeClass('fadeOut');
        $('.mm-ssmenu').removeClass('mm-ssmenu-open');
    });

    // Mobile menu
    $('.hdr-menu_toggle').on('click', function () {
        $('.main_menu').addClass('mm-open');
        $('.mm-overlay').fadeIn(300);
    });
    $('.mm-toggle-ic, .mm-overlay').on('click', function () {
        $('.main_menu').removeClass('mm-open');
        $('.mm-overlay').fadeOut(300);

        $('.mm-root-link-inside').removeClass('fadeOut');
        $('.mm-ssmenu').removeClass('mm-ssmenu-open');
    });

    const listLi = $('.main_menu * li[dropdown]');
    listLi.each(index => {
        const menu = $(listLi[index]);
        const submenu = menu.children('.submenu');
        submenu.hide();
        const icon = menu.children('span').children('.iconMenu');
        menu.click(function() {
            if (submenu.is(":hidden")) {
                submenu.show('slow');
                icon.removeClass('ic-plus').addClass('ic-cross');
            } else {
                submenu.hide('slow');
                icon.removeClass('ic-cross').addClass('ic-plus');
            }
        })
    })

}








