/*var browserVersion = {
    'firefox' : '50',
    'chrome' : '49',
    'msie' : '11',
    'safari' : '10'
};
if($(".homepage").length > 0){
    var cookieOldBrowser = Cookies.get('oldBrowser');

    if(cookieOldBrowser != 'true'){
        var actualBrowser = $.browser.name;
        var actualBrowserVersion = $.browser.version;

        if(actualBrowserVersion < browserVersion[actualBrowser]){
            var lightbox = lity('#oldBrowser');
            Cookies.set('oldBrowser', 'true');
        }
    }
}*/
