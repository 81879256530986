/**
 * Checks if the two cb are checked to enable btn accept.
 */
function checkConditions() {
    const cbUgcChecked = $('#cb-ugc').prop('checked');
    const cbCtsChecked = $('#cb-cts').prop('checked');
    const btnSubscribe = document.getElementById('validation-code-btn');

    btnSubscribe.disabled = !(cbUgcChecked && cbCtsChecked);
}

// Asserting that we're on dispo max page
if ($(".dispo-max").length > 0) {
    // Vars
    const cbUgc = $('#cb-ugc');
    const cbCts = $('#cb-cts');

    // Disabling form submit on enter
    $('#validation-code-form').on('keyup keypress', (event) => {
        const keyCode = event.keyCode || event.which;
        if (keyCode === 13) {
            event.preventDefault();
            return false;
        }
    });

    // Handling btn unsubscribe
    $('#dispo-max-unsubscribe').click(() => {
        const lightBox = lity('#popin-dispo-max-unsubscribe');

        // Hiding the original close button
        $('.lity-close').remove();

        $(".dispo-max-popin-unsubscribe-close").click(() => {
            lightBox.close();
        });
    });


    $('#validation-code-btn-unsubscribe').click(() => {
        const lightBoxFisrt = lity('#popin-dispo-max-unsubscribe');
        lightBoxFisrt.close();

        // filling popin confirmation values
        const subscriptionId = document.getElementById('popin_unsubscribe_subscriptionId').value;
        const service = document.getElementById('popin_unsubscribe_service').value;
        const popin_unsubscribe_unsubscribe_reason = document.getElementById('popin_unsubscribe_unsubscribe_reason').value;
        const popin_unsubscribe_validation_code = document.getElementById('popin_unsubscribe_validation_code').value;

        document.getElementById('popin_unsubscribe_confirmation_subscriptionId').value = subscriptionId;
        document.getElementById('popin_unsubscribe_confirmation_service').value = service;
        document.getElementById('popin_unsubscribe_confirmation_unsubscribeReason').value = popin_unsubscribe_unsubscribe_reason;
        document.getElementById('popin_unsubscribe_confirmation_validationCode').value = popin_unsubscribe_validation_code;

        const lightBox = lity('#popin-dispo-max-unsubscribe-confirmed');

        // Hiding the original close button
        $('.lity-close').remove();

        $(".dispo-max-popin-unsubscribe-confirmed-close").click(() => {
            lightBox.close();
        });

        $(".dispo-max-popin-unsubscribe-confirmed-content-buttons-canceled").click(() => {
            lightBox.close();
        });

        //$("#popin-dispo-max-unsubscribe").hide();
    });

    // Handling cb ugc change
    cbUgc.on('ifChanged', () => {
        checkConditions();
    });

    // Handling cb cts change
    cbCts.on('ifChanged', () => {
        checkConditions();
    });

    // Handling btn consult UGC
    $('#ugc').click(() => {
        const lightBox = lity('#popin-dispo-max-ugc');

        // Hiding the original close button
        $('.lity-close').remove();

        $(".dispo-max-popin-close").click(() => {
            lightBox.close();
        });

        $("#popin-ugc-btn-accept").click(() => {
            cbUgc.iCheck('check');
            checkConditions();

            lightBox.close();
        });
    });

    // Handling btn consult CTS
    $('#cts').click(() => {
        const lightBox = lity('#popin-dispo-max-cts');

        // Hiding the original close button
        $('.lity-close').remove();

        $(".dispo-max-popin-close").click(() => {
            lightBox.close();
        });

        $("#popin-cts-btn-accept").click(() => {
            cbCts.iCheck('check');
            checkConditions();

            lightBox.close();
        });
    });
}
