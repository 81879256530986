
var refDuplicate = $('.ref-duplicate');
var marginDuplicate = $('.margin-duplicate');

Array.from(refDuplicate).forEach((eachRef) => {
    eachRef.style.display = "none";
    Array.from(marginDuplicate).forEach((margDuplicate) => {
        margDuplicate.style.display = "none";
    });
});

$('.block-container-icone-arrow-desktop').on('click', function test(event) {

    var reference = event.currentTarget.classList[1];
    var defaultRef = $('.default-duplicate-' + reference);;
    var targetRef = $(".line-" + reference);
    var arrowUp = $(".arrow-up-" + reference);
    var arrowDown = $(".arrow-down-" + reference);
    var marginDuplicateByRef = $('.margin-' + reference);

    if (!matchMedia('only screen and (max-width: 480px)').matches) {
        Array.from(defaultRef).forEach((defaultRef) => {
            if (defaultRef.style.fontFamily !== "bentonsans-medium, sans-serif") {
                defaultRef.style.fontFamily = "bentonsans-medium, sans-serif";
            } else {
                defaultRef.style.fontFamily = "bentonsans-regular, sans-serif";
            }
        });
    }

    Array.from(targetRef).forEach((ref) => {
        if (ref.style.display !== "none") {
            ref.style.display = "none";
        } else {
            ref.style.display = "";
        }
    });

    Array.from(marginDuplicateByRef).forEach((margDuplicateByRef) => {
        if (margDuplicateByRef.style.display == "none") {
            margDuplicateByRef.style.display = "block"
        } else {
            margDuplicateByRef.style.display = "none"
        }
    });

    Array.from(arrowUp).forEach((up) => {
        Array.from(arrowDown).forEach((down) => {
            if (up.style.display !== "none") {
                up.style.display = "none";
                down.style.display = "unset"
            } else {
                up.style.display = "unset";
                down.style.display = "none";
            }
        });
    });
});
