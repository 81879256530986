//SCLI
//link to pharmacovigilance + open tab
if ($('.faq-content').length) {

    /*** Navigate & open tab ***/
    var anchorName = document.location.hash;
    var anchorId = anchorName.match(/\d+/);
    $('body').animate({'scrollTop': 0}, 10, function () {
        $('body').animate({'scrollTop': $(anchorName).offset().top}, 1000, function () {
            $(anchorName + ' > h2').trigger("click");
        });
    });

    /*** ANIM ON CLICK ***/
    $('.panel-collapse').on('shown.bs.collapse', function (e) {
        var $panel = $(this).closest('.panel');
        $('html,body').animate({
            scrollTop: $panel.offset().top
        }, 1000);
    });
}