$(document).resize(() => {
    resizePromotionalBlocs()
})

$(document).ready(() => {
    resizePromotionalBlocs()
})

function resizePromotionalBlocs() {
    if ($('.documents-block-promotional').length && $(window).width() < 1200) {
        $(".img-multi:nth-child(2)").css("height", $(".img-multi:nth-child(1)").css("height"))

    }
}
