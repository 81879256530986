if ($('.invoices-promotional-blocks').length && $(window).width() < 700) {
    $('.invoices-promotional-blocks').each(function () {
        const nbSlides = $(this).children('.bxslider').length;

        if (nbSlides > 2) {
            $(this).bxSlider({
                minSlides: 1,
                maxSlide: 4,
                moveSlide: 1,
                prevText: '',
                nextText: '',
                responsive: true,
                wrapperClass: 'bx-wrapper bx-wrapper-contrat-slider'
            });
        }
    })
}
