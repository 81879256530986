/*
var language = window.navigator.userLanguage || window.navigator.language;
console.log(navigator.geolocation);
 window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        "event":"page_view",
        "page_category": "homepage-login",
        "page_name": "homepage-login",
        "country": 'France test',
        "language": language,
        "asset" : "biogaran-pro",
        "environment_type" :'test',
        "user_logged" : "no"
});
*/

